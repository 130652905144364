<template>
  <div class="case">
    <img-show></img-show>
    <nav-bar :activeTab="activeTab" @changeTab="changeTab"></nav-bar>
    <div class="case-main">

    </div>
  </div>
</template>

<script>
import ImgShow from "@/components/ImgShow";
import NavBar from './components/NavBar'
export default {
  components: { ImgShow ,NavBar},
  data(){
      return{
          activeTab:'fs',
          
      }
  },
  methods:{
      changeTab(value){
          this.activeTab = value
      }
  }
};
</script>

<style lang="scss" scoped>
.case {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
